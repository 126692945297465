/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #e6ebf1 !important;
}

.hidden {
  display: none;
}

.link-button {
  color: #1890ff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}
.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.disabledOverlay {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.65);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.disabledOverlay .disabledMessage {
  padding: 30px;
  width: 100%;
  text-align: center;
  color: #788195;
  line-height: 1.1em;
}

.disabledOverlay .disabledMessageOpaque {
  padding: 15px 0;
  background-color: rgba(240, 248, 255, 0.75);
}

.globalSearchResult {
  padding: 0 !important;
}
.globalSearchResult .searchResultBody {
  display: inline-flex;
  align-items: center;
  padding: 12px 18px;
  width: 100%;
  height: 100%;
}
.globalSearchResult .searchResultBodyDeactivated {
  opacity: 0.7;
  background-color: #eeeeee;
}
.globalSearchResult .searchResultBody i {
  padding-right: 12px;
  font-size: 1.5em;
  opacity: 0.4;
}
.globalSearchResult .searchResultBody .title {
  margin: 0;
  font-weight: 300;
}
.globalSearchResult .searchResultBody .attributes {
  margin: 0;
}
.globalSearchResult .searchResultBody .attributes .asin {
  margin-right: 8px;
}
.globalSearchResult .searchResultBody .attributes .sku {

}
.globalSearchResult .searchResultBody .attributes .status {
  margin-left: 8px;
}

.tooltipStyled .title {
  color: #fff;
  font-weight: 700;
}
.tooltipStyled .alignRight {
  float: right;
  padding-left: 24px;
}
.tooltipStyled hr {
  border-color: #A5A5A5;
}

.boldNotification
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-message {
  font-weight: 700;
  color: #4B95FB;
  text-transform: uppercase;
}

.boldNotification
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-description
  > .notificationBody
  > .notificationAction {
  text-align: right;
  margin: 5px;
}

.boldNotification
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  > .ant-notification-notice-icon {
  color: #3ECF8E;
}

.boldNotification
  > .ant-notification-notice-content
  > .ant-notification-notice-with-icon
  button {
  background-color: #3ECF8E;
  border-color: #3ECF8E;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.loadingIcon {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin-top: -20px;
}

.ant-drawer-header {
  background: #fafafa !important;
  border-bottom: 1px solid #e6ebf1 !important;
}

.ant-modal-mask {
  background-color: rgba(7, 22, 47, 0.65) !important;
}

.ant-tooltip > .ant-tooltip-content > .ant-tooltip-inner > p {
  margin: 0;
}

.ant-spin-text {
  letter-spacing: 1px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

.ant-badge-status-success:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #00a854;
  content: '';
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.ant-badge-status-error:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #f5222d;
  content: '';
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.ant-badge-status-warning:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #faad14;
  content: '';
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #1890ff;
  content: '';
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.ant-badge-status-default:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  content: '';
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.shadow-small {
  box-shadow: 0 2px 5px 0 rgba(50,50,93,.1),0 1px 1px 0 rgba(0,0,0,.07);
}

.shadow-medium {
  box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
}

.shadow-large {
  box-shadow: 0 15px 35px 0 rgba(50,50,93,.1),0 5px 15px 0 rgba(0,0,0,.07);
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}
